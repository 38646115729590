<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="车辆编号" prop="vehicleId">
                <a-input v-model="queryParam.vehicleId" placeholder="请输入车辆编号" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery" id="tableQueryBtn"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button
            type="primary"
            @click="$refs.createForm.handleAdd(queryParam.vehicleId)"
            v-hasPermi="['tenant:iot:vehicleAbility:add']"
          >
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['tenant:iot:vehicleAbility:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button
            type="danger"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['tenant:iot:vehicleAbility:remove']"
          >
            <a-icon type="delete" />删除
          </a-button>
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:vehicleAbility:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
            type="dashed"
            shape="circle"
            :loading="loading"
            :style="{ float: 'right' }"
            icon="reload"
            @click="getList"
          /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" :abilityOptions="abilityOptions" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="dictValue" slot-scope="text, record">
          {{ dictValueFormat(record) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a href="javascript:;" @click="goToCamera(record)" v-if="record.dictValue.split(',').indexOf('camera') >= 0">
            摄像头管理
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicleAbility:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tenant:iot:vehicleAbility:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicleAbility:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:iot:vehicleAbility:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listVehicleAbility, delVehicleAbility, exportVehicleAbility } from '@/api/iot/vehicleAbility'
import CreateForm from './modules/CreateForm'

export default {
  name: 'VehicleAbility',
  components: {
    CreateForm
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 车辆能力:摄像头等字典
      abilityOptions: [],
      // 查询参数
      queryParam: {
        vehicleId: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '车辆编号',
          dataIndex: 'vehicleId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车辆能力',
          dataIndex: 'dictValue',
          scopedSlots: { customRender: 'dictValue' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '25%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    var query = this.$route.query
    const vId = query.vehicleId
    console.log('vehicleId === ' + vId)
    if (vId) {
      this.queryParam.vehicleId = vId
    }
    this.getList()
    this.getDicts('iot_vehicle_ability').then((response) => {
      this.abilityOptions = response.data
    })
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询车辆能力关联列表 */
    getList() {
      this.loading = true
      listVehicleAbility(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    // 车辆能力:摄像头等字典翻译
    dictValueFormat(row, column) {
      var dv = row.dictValue.split(',')
      let v = ''
      for (let i = 0; i < dv.length; i++) {
        if (dv.length === 1) {
          v = this.selectDictLabel(this.abilityOptions, dv[i])
        } else if (dv.length - 1 === i) {
          v = v + this.selectDictLabel(this.abilityOptions, dv[i])
        } else {
          v = v + this.selectDictLabel(this.abilityOptions, dv[i]) + ','
        }
      }
      return v
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        vehicleId: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    goToCamera(row) {
      this.$router.push('/device/vehicleAbilityCamera?vehicleId=' + row.vehicleId)
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delVehicleAbility(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportVehicleAbility(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    }
  },
  activated() {
    console.log('activated')
  }
}
</script>
